<template>
  <v-main class="container-prosker">
    <v-col cols="12" class="m-0 p-0">
      <hero-banner
        :title="$t('clientBannerTitle')"
        :img-url="'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg'"
        :on-cta-click="goToHowItWorks"
        :text-link-route="'social.subscription-page'"
        :cta-text="$t('begin')"
        :text-link-text="$t('wantToHire')"
      />
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <p class="secondary-prosk mx-auto w-75 text-h5 text-md-h3 text-center">{{ $t('servicePlatformClient') }}</p>
    </v-col>
    <v-col cols="10" offset="1">
      <save-button :save="goToHowItWorks" :size="'block'" :loading="false" :message="$t('howDoesItWork')"/>
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <video-banner :video-url="video.url" />
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <categories-carousel />
    </v-col>
    <v-col cols="12" class="m-0 py-8">
      <prosker-featured />
    </v-col>
    <v-col cols="12" class="m-0 py-8 grey lighten-2">
      <Testimonials />
    </v-col>
  </v-main>
</template>

<script>
import Testimonials from '@/components/socialvue/testimonials/Testimonials';
import HeroBanner from '@/components/socialvue/banners/HeroBanner';
import VideoBanner from '@/components/socialvue/banners/VideoBanner';
import SaveButton from '@/components/socialvue/buttons/SaveButton';
import ProskerFeatured from '@/components/socialvue/carousel/ProskerFeatured.vue';
import CategoriesCarousel from '@/components/socialvue/carousel/CategoriesCarousel';

export default {
  name: 'HomeClients',
  components: {
    Testimonials,
    HeroBanner,
    SaveButton,
    VideoBanner,
    ProskerFeatured,
    CategoriesCarousel
  },
  data () {
    return {
      video: {
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        title: this.$t('videoTitle'),
        ctaText: this.$t('wantToSignUp')
      }
    };
  },
  methods: {
    goToHowItWorks () {
      this.$router.push({ name: 'social.how-works-clients' });
    }
  }
};
</script>
